<template>
  <content-wrapper :title="labels.MENU_NEWS">
    <template slot="actions">
      <el-button size="small" type="success" @click="handleAdd">
        <i class="el-icon-plus mr-1"></i>
        {{ labels.LBL_ADD }}
      </el-button>
    </template>

    <el-table
      v-loading="loading"
      :data="newsList"
      :default-sort="{ prop: 'created_at', order: 'descending' }"
      :empty-text="labels.LBL_NO_DATA"
    >
      <el-table-column prop="title" :label="labels.LBL_TITLE" sortable>
      </el-table-column>

      <el-table-column :label="labels.LBL_PUBLISHED_ON">
        <template slot-scope="scope">
          <span v-if="scope.row.published_at">
            {{ scope.row.published_at | readableDate }}</span
          >
          <el-tag v-else size="mini" type="info">
            {{ labels.LBL_UNPUBLISHED }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="editor" :label="labels.LBL_EDITOR">
      </el-table-column>

      <el-table-column align="right" width="300">
        <template slot="header" slot-scope="scope">
          <el-row type="flex">
            <el-input
              v-model="search"
              size="mini"
              :placeholder="labels.LBL_TYPE_2_SEARCH"
              class="ml-1"
            />
          </el-row>
        </template>

        <template slot-scope="scope">
          <el-button
            size="mini"
            type="info"
            icon="el-icon-edit"
            circle
            @click="handleEdit(scope.row)"
          >
          </el-button>

          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            circle
            @click="handleDelete(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="pagination"
      :current-page.sync="pagination.current_page"
      :total="pagination.total"
      :page-size="parseInt(pagination.per_page)"
      layout="total, prev, pager, next"
      class="my-2"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </content-wrapper>
</template>

<script>
  import _ from "lodash";
  import { mapState } from "vuex";
  import { labels } from "@/common";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";

  export default {
    name: "News",

    components: {
      ContentWrapper,
    },

    data() {
      return {
        labels,

        search: "",
        params: {
          type: null,
          page: 1,
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        news: (state) => state.news.news,
      }),

      newsList() {
        return this.news && this.news.data;
      },

      pagination() {
        return this.news && this.news.meta;
      },
    },

    watch: {
      search: _.debounce(function(nv) {
        this.handleSearch(nv);
      }, 500),

      "params.page": {
        immediate: true,
        handler(nv) {
          if (nv) this.loadAllNews();
        },
      },
    },

    methods: {
      loadAllNews() {
        this.$store.dispatch("news/getAllNews", this.params);
      },

      handlePageChange(val) {
        this.params.page = val;
      },

      handleSearch(search) {
        this.$store.dispatch("news/getAllNews", { ...this.params, search });
      },

      handleAdd() {
        this.$router.push({ name: "Add News" });
      },

      handleEdit({ id }) {
        this.$router.push({ name: "Edit News", params: { newsId: id } });
      },

      handleDelete({ id }) {
        this.$confirm(`${labels.CONF_DELETE}?`, labels.LBL_WARN, {
          confirmButtonText: labels.LBL_YES,
          cancelButtonText: labels.CANCEL,
          type: labels.LBL_WARN.toLowerCase(),
        })
          .then(() => {
            this.$store.dispatch("news/deleteNews", id);
            console.log(id);
          })
          .catch(() => {
            this.$message({
              type: labels.LBL_INFO.toLowerCase(),
              message: labels.CNCL_DELETE,
            });
          });
      },
    },
  };
</script>
